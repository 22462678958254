<template>
  <card-component title="Edit Profile" icon="account-circle">
    <form @submit.prevent="submit">

      <b-field label="ID" horizontal>
          {{ profile.id }}
      </b-field>

      <b-field horizontal label="Login" >
        {{ profile.login }}
      </b-field>

      <b-field horizontal label="Name">
        <b-input v-model="form.name" type="text" required/>
      </b-field>

      <b-field horizontal label="E-mail">
        <b-input v-model="form.email" type="email" required/>
      </b-field>

      <b-field label="Created at" horizontal>
        {{ profile.created_at | moment('YYYY.MM.DD H:mm:ss') }}
      </b-field>

      <b-field label="Updated at" v-if="profile.updated_at" horizontal>
        {{ profile.updated_at | moment('YYYY.MM.DD H:mm:ss') }}
      </b-field>

      <b-field label="API token" horizontal class="token">
        <ApiToken :value="profile.api_token"></ApiToken>
      </b-field>

      <hr>
      <b-field horizontal>
        <div class="control">
          <b-button :class="{'is-loading':isLoading}"
                    type="is-success"
                    icon-left="content-save"
                    native-type="submit"
          >Save</b-button>
        </div>
      </b-field>
    </form>
  </card-component>
</template>

<script>
import CardComponent from '@/components/CardComponent.vue';
import ApiToken from '@/components/ApiToken.vue';

export default {
  name: 'ProfileUpdateForm',
  components: {
    ApiToken,
    CardComponent,
  },
  data() {
    return {
      isLoading: false,
      form: {
        name: this.$store.state.profile.name,
        email: this.$store.state.profile.email,
      },
    };
  },
  computed: {
    profile() {
      return this.$store.state.profile;
    },
  },
  methods: {
    async submit() {
      this.isLoading = true;
      const { data } = await this.$http.put('/v1/profile', this.form);
      this.$store.commit('profile', data);
      this.form.name = data.name;
      this.form.email = data.email;
      this.isLoading = false;
      this.$toast.success('Profile was saved');
    },
  },
};
</script>
