<template>
  <div class="">
    <section class="section is-main-section">
      <b-field>
        <b-input
          placeholder="Search..."
          type="search"
          icon="magnify"
          v-model="text"
          @keyup.native.enter="search"
          ref="input"
        />
        <b-button v-on:click="search">Search</b-button>
      </b-field>

      <b-table
        :data="results"
        :loading="loading"
        striped
        hoverable
        bordered
        pagination-size="is-small"
        :total="total"
        :default-sort-direction="defaultSortOrder"
        :default-sort="[sortField, sortOrder]"
      >
        <b-table-column field="type" label="Type" sortable v-slot="props">
          {{ props.row.type }}
        </b-table-column>

        <b-table-column field="id" label="ID" sortable v-slot="props">
          {{ props.row.id }}
        </b-table-column>

        <b-table-column field="name" label="Name" sortable v-slot="props">
          {{ props.row.name }}
        </b-table-column>

        <b-table-column field="label" label="Label" sortable v-slot="props">
          {{ props.row.label }}
        </b-table-column>

        <b-table-column field="login" label="Login" sortable v-slot="props">
          {{ props.row.login }}
        </b-table-column>

        <b-table-column field="email" label="Email" sortable v-slot="props">
          {{ props.row.email }}
        </b-table-column>

        <b-table-column field="actions" label="Actions" v-slot="props">
          <router-link :to="{ name: props.row.type, params: { id: props.row.id } }" class="mr-1">
            <b-button size="is-small" title="Detail">
              <b-icon icon="eye" size="is-small" />
            </b-button>
          </router-link>
          <router-link
            v-if="props.row.type === 'device'"
            :to="{ name: 'device-transfer', params: { id: props.row.id } }"
          >
            <b-button size="is-small" title="Device transfer">
              <b-icon icon="folder-swap-outline" size="is-small" />
            </b-button>
          </router-link>
        </b-table-column>

        <b-table-column field="other" label="" v-slot="props">
          <router-link
            v-if="props.row.type === 'device'"
            :to="{ name: 'messages', params: { deviceId: props.row.id }, query: { page: 1 } }"
          >
            Messages
          </router-link>

          <router-link v-if="props.row.type === 'group'" :to="{ name: 'devices', params: { groupId: props.row.id } }">
            Devices
          </router-link>

          <router-link
            v-if="props.row.type === 'organization'"
            :to="{ name: 'groups', params: { organizationId: props.row.id } }"
          >
            Groups
          </router-link>
        </b-table-column>

        <template slot="empty">
          <section class="section">
            <div class="content has-text-grey has-text-centered">
              <p v-if="searchCnt > 0">Nothing was found.</p>
              <p v-if="searchCnt == 0">Please enter the search text.</p>
            </div>
          </section>
        </template>
      </b-table>
    </section>
  </div>
</template>

<script>
export default {
  name: 'Search',
  components: {},
  data() {
    return {
      text: '',
      results: [],
      total: 0,
      sortField: 'name',
      sortOrder: 'desc',
      defaultSortOrder: 'asc',
      loading: false,
      searchCnt: 0,
    };
  },
  created() {},
  mounted() {
    this.$refs.input.focus();
  },
  methods: {
    async search() {
      if (this.text.length < 3) return;

      this.loading = true;

      const res = await this.$http.get('/v1/search', {
        params: {
          text: this.text,
        },
      });

      this.results = res.data;
      this.total = parseInt(res.headers['x-total'], 10);
      this.searchCnt += 1;
      this.loading = false;
    },
  },
};
</script>
