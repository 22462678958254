<template>
  <div>
    <section class="section is-main-section">
      <profile-update-form class="tile is-child"/>
      <password-update-form class="tile is-child"/>
    </section>
  </div>
</template>

<script>
import ProfileUpdateForm from '@/components/profile/UpdateForm.vue';
import PasswordUpdateForm from '@/components/profile/PasswordForm.vue';

export default {
  name: 'Profile',
  components: { PasswordUpdateForm, ProfileUpdateForm },
};
</script>
