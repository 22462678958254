<template>
  <card-component title="Change Password" icon="lock">
    <form @submit.prevent="submit">
      <b-field horizontal label="Current password" message="Required. Your current password">
        <b-input name="password_current" type="password" v-model="form.password_current"
                 required
                 autcomplete="current-password"/>
      </b-field>
      <hr>
      <b-field horizontal label="New password" message="Required. New password">
        <b-input name="password" type="password" v-model="form.password"
                 required autocomplete="new-password"/>
      </b-field>
      <b-field horizontal label="Confirm password" message="Required. New password one more time">
        <b-input name="password_confirmation" type="password"
                 v-model="form.password_confirmation" required
                 autocomplete="new-password"/>
      </b-field>
      <hr>
      <b-field horizontal>
        <div class="control">
          <b-button :class="{'is-loading':isLoading}"
                    type="is-success"
                    icon-left="content-save"
                    native-type="submit"
          >Submit</b-button>
        </div>
      </b-field>
    </form>
  </card-component>
</template>

<script>
import CardComponent from '@/components/CardComponent.vue';

export default {
  name: 'ProfilePasswordForm',
  components: {
    CardComponent,
  },
  data() {
    return {
      isLoading: false,
      form: {
        password_current: null,
        password: null,
        password_confirmation: null,
      },
    };
  },
  methods: {
    async submit() {
      if (this.form.password !== this.form.password_confirmation) {
        this.$toast.error('Passwords do not match');
        return;
      }

      this.isLoading = true;

      this.$http.put('/v1/profile/change-password', {
        old_password: this.form.password_current,
        new_password: this.form.password,
      })
        .then(() => {
          this.$toast.success('Password changed');
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
